import React from 'react';
import Address from '../address/Address';
import ClassList from '../class-list/ClassList';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <Address />
      <ClassList />
    </footer>
  );
}

export default Footer;