import React from 'react';
import SearchForm from './SearchForm';
import { withRouter } from 'react-router-dom';

class SearchFormRouterWrapper extends React.Component {
  render() {
    return <SearchForm {...this.props} />
  }
}

export default withRouter(SearchFormRouterWrapper);