/**
 * Classes page
 * @flow
 */
import React from 'react';
import { Helmet } from 'react-helmet';
import { CLASSES_IMAGE } from './Classes.data';
import { OPEN, FEATURE } from '../../data/Data';
import ImageHeading from '../../components/image/ImageHeading';
import CheckinButton from '../../components/checkin-button/CheckinButton';
import ClassDetails from '../../components/class-details/ClassDetails';
import './Classes.css';

type Props = {};

const Classes = (props: Props) => {
  const title = 'Aikido classes';
  const description = 'Open handed classes Mondays and Wednesdays, Mt Waverley. Weapons classes Saturdays, Ringwood';

  return (
    <div className="Classes">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <ImageHeading embed={false} image={CLASSES_IMAGE} title="Classes" />
      <section>
        <div className="heading">
          <h2>Aikido classes</h2>
          {OPEN ? <CheckinButton /> : null}
        </div>
        {OPEN ? <ClassDetails /> : <div className="closed"><p>{FEATURE.text}</p></div>}
      </section>
    </div>
  );
}

export default Classes;
