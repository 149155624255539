/**
 * CartButton
 * @flow
 */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { BasketType } from '../../../types/Types';
import './CartButton.css';

type Props = {
  basket: BasketType
}

class CartButton extends React.Component<Props> {
  static defaultProps = {
    basket: {
      items: []
    }
  };

  count = () => {
    const { basket } = this.props;
    let count = 0;

    if (basket.items.length === 0) return count;
    
    for (var i=0; i< basket.items.length; i++) {
      const quant = basket.items[i].quantity;
      if (quant) {
        count += quant;
      }
    }

    return count;
  }

  render() {
    const { basket } = this.props;

    let count = 0;

    for (var i=0; i< basket.items.length; i++) {
      const quant = basket.items[i].quantity;
      if (quant) {
        count += quant;
      }
    }

    return (
      <div className="cart-button">
        <NavLink to={`/cart`} activeClassName="active" exact={true}>
          <FontAwesomeIcon icon="shopping-cart" />
          {
            (count > 0)
              ? <span className="count">{count}</span>
              : null
          }
        </NavLink>
      </div>
    );
  }
}

export default CartButton;


