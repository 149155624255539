/**
 * Home page
 * 
 * @flow
 */
import React from 'react';
import HomeFeature from '../../components/home-feature/HomeFeature';
import Reviews from '../../components/reviews/Reviews';
import LearnAikidoDotPoints from '../../components/dot-points/LearnAikidoDotPoints';
import type BugsnagClient from '@bugsnag/js';
import './Home.css';

type Props = {
  jest: boolean,
  bugsnagClient: BugsnagClient
};

class Home extends React.Component<Props> {
  static defaultProps = {
    jest: false,
  };

  render () {
    return (
      <div className="home">
        <HomeFeature />
        <LearnAikidoDotPoints {...this.props} />
        <Reviews {...this.props} />
      </div>
    );
  }
}

export default Home;
