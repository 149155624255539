import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menu from './Menu';
import flag from './Gay_Pride_Flag.svg';
import './Nav.css';

class Nav extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      items: props.items | [],
      width: 0,
      height: 0
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  renderMenus() {
    const { items } = this.props;
    const { width } = this.state;

    if (items.length === 0) return null;

    const menus = [];
    for (let i=0; i<items.length ; i++ ) {
      menus.push(<Menu key={i} {...this.props} index={i} item={items[i]} width={width} />);
    }
    return menus;
  }

  render() {
    const { close, items, show } = this.props;
    const { width, height } = this.state;

    if (!items || items.length === 0) return null;

    let style = {};
    if (width < '1000') {
      style = {
        height: height * 2,
      }
    }

    return (
      <div className="main-nav">
        <ul
          className={show ? 'show' : 'hide'}
          style={style}
          >
          <li onClick={close}>
            <NavLink to="/" activeClassName="current" exact={true} tabIndex="0">
              <FontAwesomeIcon icon="home" />
            </NavLink>
          </li>
          {this.renderMenus()}
          <li onClick={close} className="flag">
            <NavLink to='/lgbtiq-safe-martial-arts' activeClassName="active" exact={true}  tabIndex="0">
              <img src={flag} className="flag" alt="LGBTIQ pride flag"/>
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default Nav;