/**
 * Reviews component
 * 
 * @flow
 */

import React from 'react';
import Review from './Review';
import {REVIEWS} from './Reviews.data';

import './Reviews.css';

type Props ={};

class Reviews extends React.Component<Props> {

  
  render() {
    let reviews = [];

    for (let i=0; i<REVIEWS.length; i++) {
      const review = REVIEWS[i];
      reviews.push(<Review key={i} {...review} />);
    }

    return (
      <div className="Reviews">
        <h2>Reviews</h2>
        <div className="review-listing">
          {reviews}
        </div>
      </div>
    );
  }
}

export default Reviews;
