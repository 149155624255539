/**
 * Header
 * 
 * @flow
 */
import React from 'react';
import Logo from '../logo/Logo';
import { NavLink } from 'react-router-dom';
import SearchFormRouterWrapper from '../search/SearchFormRouterWrapper';
import CartButton from '../../pages/shop/cart/CartButton';
import './Header.css';

type Props = {};
type State = {
  tagLine: boolean,
};

class Header extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tagLine: true
    }
  }

  /**
   * Show tagline
   * 
   * Used to hide tagline when searching on mobile
   */
  showTagline = (tagLine: boolean) => {
    this.setState({
      tagLine
    });
  }

  render() {
    const {tagLine} = this.state;
    return (
      <header>
        <NavLink to="/">
          <Logo />
        </NavLink>
        {
          tagLine
            ? 
              <React.Fragment>
                <div className="tagline">Be your best self</div>
              </React.Fragment>
            : null
        }
        <SearchFormRouterWrapper {...this.props} header={true} showTagline={this.showTagline} />
        <CartButton {...this.props} />
      </header>
    );
  }
}

export default Header;
