import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Nav.css';

class Nav extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      isActive: false,
      item: this.props.item | null,
    }
  }

  onClick = () => {
    const { width } = this.props;
    const { expand } = this.state;

    if (width < 1000 && !expand) {
      this.expand();
      return true;
    }

  }

  expand = () => {
    const { active, expand } = this.state;
    const { width } = this.props;

    if (active || width > 1000) return false;

    this.setState({
      expand: !expand
    });
  }

  /**
   * Render the submenu
   */
  renderSub = (items = []) => {
    if (items.length === 0) return null;
    
    let sub = [];
    for (let i=0; i<items.length ; i++ ) {
      sub.push(
        <li key={i} onClick={this.props.close}><NavLink to={items[i].path} activeClassName="current">{items[i].title}</NavLink></li>
      );
    }
    return sub;
  }

  /**
   * Are we on an active path
   */
  active = () => {
    const { item, location } = this.props;
    if (!item) return null;

    if (item.path === location.pathname) {
      return true;
    }

    if (!item.subMenu) return false;

    const subMenu = item.subMenu;
    for (let i=0; i<subMenu.length ; i++ ) {
      if (subMenu[i].path === location.pathname) {
        return true;
      }
    }

    return false;
  }

  render() {
    const { item , index, close} = this.props;
    const { expand} = this.state;

    if (!item) return null;

    const active = this.active();
    const chevron = (item.subMenu && item.subMenu.length > 0) ? true : false;
    const classes = `${(item.subMenu) ? ' parent' : '' }${(active) ? ' active' : ''}${(expand) ? ' expand' : ''}`
    return (
      <li key={index} className={`menu-item${classes}`}>
        {
          (chevron) 
            ? (<React.Fragment>
                <FontAwesomeIcon icon={"chevron-down"} className="chevron down" onClick={this.expand} />
                <FontAwesomeIcon icon={"chevron-right"} className="chevron right" onClick={this.expand} />
              </React.Fragment>)
            : null
        }
        <NavLink to={item.path} activeClassName="current" onClick={close}>
          {item.title}
        </NavLink>
        {
          (item.subMenu)
            ? (
              <ul className={`${(active || expand) ? 'expand' : 'closed'}`}>
                {this.renderSub(item.subMenu)}
              </ul>
              )
            : null
        }
      </li>
    );
  }
}

export default Nav;