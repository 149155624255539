/**
 * Home Feature
 * @flow
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import { FEATURE, IMAGE_ROOT } from '../../data/Data';
import './HomeFeature.css';

const HomeFeature = () => {
  const { text, link } = FEATURE;
  return (
    <div className="HomeFeature">
      <Link to="/classes">
      <img src={`${IMAGE_ROOT}vintage-japanese-woodblock-print-c-1950-5493.jpg`} alt={FEATURE.text}/>
        <h1>{text}</h1>
      </Link>
      
      <div className="actions">
        {link ? (
          <Link className="button" to={link}>
            <FontAwesomeIcon icon="eye" />
            View details
          </Link>
          ) : null}
      </div>
     
    </div>
  );
}

export default HomeFeature;