/**
 * Alert
 * 
 * @flow
 */
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import { FEATURE } from '../../data/Data';
import './Alert.css';

type Props = {};
type State = {
  show: boolean,
}

class Alert extends React.Component<Props, State>{
  constructor(props: Props) {
    super(props);

    this.state = {
      show: true
    }
  }

  close = () => {
    this.setState({
      show: false
    });
  }

  render() {
    const {show} = this.state;

    if (show ) {
      return (
        <div className="alert">
          <FontAwesomeIcon icon="user-ninja" />
          <span className="text">
            {FEATURE.text}
          </span>
          {FEATURE.link ? <Link to={FEATURE.link} className="button">Details</Link> : null}
          <div className="close" onClick={this.close} tabIndex="0">
            <FontAwesomeIcon icon="times" title="Close" />
          </div>
        </div>
      );
    }
    return null;
  }
}

export default Alert;
