/**
 * Class List
 * @flow
 */
import React from 'react';
import { OPEN } from '../../data/Data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { nextClasses } from '../../functions/Functions';
import './ClassList.css';

const Classlist = () => {
  let classes = nextClasses(4);
  let display= [];

  if (!OPEN) {
    return null;
  }

  for (let i=0; i<classes.length; i++) {
    const { date, end, link, location, start, title} = classes[i];

    display.push(
      <li key={i} className="class-details">
        <div className="date-time">
          <strong>{start} – {end}, {date.format('dddd D MMM YYYY')}</strong>
        </div>
        <div className="class-name">
          <a href={link}>{title}</a>
        </div>
        <div className="location">{location.title}</div>
      </li>
    );
  }

  return (
    <div className="class-list">
      <h2><FontAwesomeIcon icon="user-ninja" /> <Link to="/classes">Upcoming classes</Link></h2>
      <ul>
        {display}
      </ul>
      <div className="details">
        <Link to="/classes">
          <FontAwesomeIcon icon="info-circle" />Class details
        </Link>
      </div>
    </div>
  );
}

export default Classlist;
