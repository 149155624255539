/**
 * Checkin Button
 * @flow
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

type Props = {
  className: string;
}

const CheckinButton = (props: Props) => {
  const {className} = props;

  return (
    <Link className={`button checkin ${className}`} to="/classes/checkin">
      <FontAwesomeIcon icon="check" />
      Check in
    </Link>
  );
}

CheckinButton.defaultProps = {
  className: ''
};

export default CheckinButton;
