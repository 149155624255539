/**
 * Review component
 * @flow
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {PROD_URL} from '../../data/Data';
import {PRODUCTS} from '../../pages/shop/Products.data';
import './Review.css';

type Props = {
  author: string,
  image: string,
  reviewBody: string,
  reviewRating: {
    '@type': string,
    ratingValue: number,
    bestRating: number,
  },
  itemReviewed: {
    name: string,
    alternativeName: string,
    url: string,
    slug: string,
  }
};

class Review extends React.Component<Props> {

  static defaultProps = {
    author: 'Van Khuu',
    image: '',
    reviewBody: 'I have been training in this dojo for many years and enjoy it very much. Ajax sensei is a great instructor, his teaching methods is friendly, clear and effective. I Highly recommend the dojo to anyone want to learn Aikido.',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: 5,
      bestRating: 5
    },
    itemReviewed: {
      name: PRODUCTS[0].name,
      alternativeName: 'Adults / teens Aikido',
      url: `${PROD_URL}${PRODUCTS[0].id}`,
      slug: '/learn-aikido'
    }
  }
  render() {
    const {
      author,
      reviewBody,
      reviewRating,
      itemReviewed,
    } = this.props;
    const rating = reviewRating.ratingValue;
    const max = reviewRating.bestRating;

    let stars = [];
    let i;
    for (i=0; i<rating; i++) {
      stars.push(<FontAwesomeIcon key={i} icon="star" className="rated" />)
    }

    if (i<max) {
      for (let x=i; x<max; x++) {
        stars.push(<FontAwesomeIcon key={x} icon="star" className="spare" />)
      }
    }

    return (
      <div className="Review">
        <div className="rating">{stars}</div>
        <p>{reviewBody}</p>
        <div className="author">{author}</div>
        <div className="learn-more">
            <Link to={itemReviewed.slug}>
              {itemReviewed.alternativeName}
              <FontAwesomeIcon icon="chevron-right" />
            </Link>
            </div>
      </div>
    );
  }
}

export default Review;
